import React, { useState, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { getRoom, apiBase } from "../helpers";

const EventPage = (props: any) => {
  const [authenticatedUser, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [room, setRoom] = useState(null);
  const [isHost, setIsHost] = useState(false);
  const [joinCall, setJoinCall] = useState(false);

  const windowUrl = props.location.search;
  const params = new URLSearchParams(windowUrl);
  const eventId = params.get("eventid");
  const userEmail = params.get("email");

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    })();
  }, []);

  useEffect(() => {
    getRoomFromApi();
  }, [authenticatedUser]);

  const getRoomFromApi = async () => {
    const room = await getRoom(eventId);
    const result = await room.json();
    setRoom(result);

    if (authenticatedUser) {
      setIsHost(result.hostGuid === authenticatedUser.attributes.sub);
    }

    if (result.roomToken) {
      setToken(result.roomToken);
    }
    console.log("this is room ", result);
  };

  const handleHostStart = async () => {
    console.log("in handle host start");
    await getIndividualRoomToken();
    setJoinCall(true);
  };

  const getIndividualRoomToken = async () => {
    const url = `${apiBase}/api/video/token/event/${eventId}`;
    const result = await fetch(url, {
      method: "get",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    if (result.twilioJWTToken) {
      console.log("this is room token ", result.twilioJWTToken);
      setToken(result.twilioJWTToken);
    } else {
      // handle this
      console.log("CALL IS OVER AND HAS ENDED");
    }
  };

  const handleJoin = async () => {
    await getIndividualRoomToken();
    setJoinCall(true);
    console.log("in handle join ok ", token, " ", joinCall);
  };

  const handleLogout = useCallback((event) => {
    setToken(null);
  }, []);

  return (
    <div>
      <button type="submit" onClick={handleJoin}>
        Join Call
      </button>
    </div>
  );
};

export default EventPage;
