import { apiBase } from "./utilities";
import { UserPostData, IRoom, ActiveRoom, IScheduledEvent } from "../models";

export const postRequestAsync = async (jsonPostData: any, urlPath: string) => {
  const url = `${apiBase}/api/${urlPath}`;
  await fetch(url, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonPostData),
  });
};

export const getRequestAsync = async (urlPath: string) => {
  const url = `${apiBase}/api/${urlPath}`;
  return await getAsync(url);
};

export const getEvent = async (eventId: string): Promise<IChefyRoom> => {
  const result = await getRequestAsync(`event/${eventId}`);
  return result;
};

export const getRoom = async (eventId: string): Promise<ActiveRoom> => {
  const getRoomUrl = `${apiBase}/api/room/name/${eventId}`;
  return await getAsync(getRoomUrl);
};

export const getTwilioToken = async (eventId: string): Promise<any> => {
  const url = `${apiBase}/api/room/token/event/${eventId}`;
  return await getAsync(url);
};

export const createEvent = async(scheduleEventData  : IScheduledEvent) => {
  const createEventUrl = `${apiBase}/api/event/schedule`;
  return await postAsync(scheduleEventData, createEventUrl);
}

export const saveUserInfo = async (userPostData: UserPostData) => {
  const url = `${apiBase}/api/user/create`;
  return await postAsync(userPostData, url);  
};

const getAsync = async(url : string) => {
  const result = await fetch(url, {
    method: "get",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

  return result;
}


const postAsync = async(data: any, url : string) => {
  const result = await fetch(url, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-Type": "applicatin/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
  return result;
}
